import { take } from 'rxjs';
import { UNITS } from '../../utils/units.enum';
import { getContainer } from '@vegga/front-store';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('draintraysController', draintraysController);

  draintraysController.$inject = ['$rootScope', '$scope', '$state', 'trays7Factory'];

  function draintraysController($rootScope, $scope, $state, trays7Factory) {
    var vm = this;
    vm.activeList;
    vm.save;
    vm.cancel;
    vm.open;
    vm.save = save;
    vm.cancel = cancel_edition;
    vm.open = open;
    vm.UNITS = UNITS;
    vm.devicesFacade = getContainer().resolve('devicesFacade');

    activate();

    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$.pipe(take(1)).subscribe((currentUnit) => {
        if (!currentUnit) {
          $state.go('units');
        }
        vm.currentUnit = currentUnit;
        vm.currentState = $state.includes;

        loadTrays();
        vm.selected = 0;
      });
    }

    function loadTrays() {
      vm.trays = [];
      return trays7Factory.getTrays(vm.currentUnit.id).then(function (data) {
        vm.trays = data.plain();
      });
    }

    function open(index) {
      if (vm.selected === index) {
        vm.selected = null;
      } else {
        vm.selected = index;
      }
    }

    function save() {
      trays7Factory.updateAll(vm.trays, vm.currentUnit.id).then((response) => {
        const sortedTrays = response.sort((a, b) => a.pk.id - b.pk.id);
        vm.trays = sortedTrays;
      });
    }

    function cancel_edition() {
      $state.go('outputs.watermix', { unit: vm.currentUnit }, { reload: 'outputs.watermix' });
    }
  }
})();
