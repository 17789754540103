import { UNITS } from '../utils/units.enum';
import { Subject } from 'rxjs';
import { getContainer } from '@vegga/front-store';
import { take } from 'rxjs/operators';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('outGeneralController', outGeneralController);

  outGeneralController.$inject = ['$rootScope', '$scope', '$state', 'configFactory'];

  function outGeneralController(
    $rootScope,
    $scope,
    $state,

    configFactory
  ) {
    var vm = this;
    var backup;
    vm.activeList;
    vm.save;
    vm.cancel;
    vm.open;
    vm.save = save;
    vm.cancel = cancel_edition;
    vm.open = open;
    vm.UNITS = UNITS;
    vm.selected = 0;
    vm.destroy$ = new Subject();
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    activate();

    function activate() {
      vm.currentState = $state.includes;
      vm.devicesFacade.legacyUnit$.pipe(take(1)).subscribe((currentUnit) => {
        if (!currentUnit) {
          $state.go('units');
          return;
        }

        vm.currentUnit = currentUnit;
        if (
          $state.current.name === 'outputs.diesel' &&
          vm.currentUnit.inoptions.ge !== null &&
          !vm.currentUnit.inoptions.ge
        ) {
          $state.go('outputs.diesel');
        }
        if (
          $state.current.name === 'outputs.pressure' &&
          vm.currentUnit.inoptions.rp !== null &&
          !vm.currentUnit.inoptions.rp
        ) {
          $state.go('outputs.pressure');
        }
        if (
          $state.current.name === 'outputs.ph' &&
          vm.currentUnit.inoptions !== undefined &&
          vm.currentUnit.inoptions.ph !== null &&
          !vm.currentUnit.inoptions.ph
        ) {
          $state.go('outputs.ph');
        }

        loadConfig();
      });
    }

    function open(index) {
      if (vm.selected === index) {
        vm.selected = null;
      } else {
        vm.selected = index;
      }
    }

    function loadConfig() {
      var params = {};
      vm.loading = true;
      configFactory.get(vm.currentUnit.id, params).then((result) => {
        vm.config = result;
        backup = angular.copy(vm.config);
        vm.loading = false;
      });
    }

    function save() {
      vm.form.$setPristine();
      vm.config.unittype = angular.copy(vm.currentUnit.type);

      vm.loading = true;
      configFactory.updateConfig(vm.currentUnit.id, vm.config).then((result) => {
        if (vm.currentUnit.type === UNITS.A_7000) {
          const configWithSortedAlarms = { ...result, alarms: result.alarms.sort((a, b) => a.pk.id - b.pk.id) };
          vm.config = configWithSortedAlarms;
        } else {
          vm.config = result;
        }
        backup = angular.copy(vm.config);
        vm.loading = false;
      });
    }

    function cancel_edition() {
      vm.config = angular.copy(backup);
    }

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });

    $scope.$on('formCancel', () => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );
  }
})();
